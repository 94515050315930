const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/", "/impressum"],
    exact: true,
    component: "Impressum",
  },
  {
    path: ["/", "/datenschutz"],
    exact: true,
    component: "Datenschutz",
  },
  {
    path: ["/", "/thanks"],
    exact: true,
    component: "Thanks",
  },
];

export default routes;
